<template>
  <div class="container" v-wechat-title="$route.meta.title=desc.package_name" :img-set="desc.package_cover">
    <div class="cover">
      <van-image v-if="contentType != 2" class="cover-img" :src="desc.package_cover" />
      <template v-if="contentType == 3">
        <div class="cover-mp3">
          <audio ref="audio" :src="audioUrl" controls="controls"></audio>
        </div>
      </template>
      <template v-if="contentType == 2">
        <div id="dplayer" style="width:100%;height:180px" ></div>
      </template>
    </div>
    <van-tabs v-model="tabActive">
      <van-tab title="简介">
        <div class="desc">
          <div class="desc-hd">
            <div class="desc-hd-h2">{{ desc.package_name }}</div>
            <div class="desc-hd-bottom">
              <div class="desc-hd-bottom-hd">
                <van-icon class="mr5" class-prefix="icon" size="14" name="IndentOutline" />
                <span>{{ desc.package_count }}</span>
              </div>
              <div v-if="desc.package_price" class="desc-hd-bottom-bd">
                <span>￥</span>{{ desc.package_price }}
              </div>
            </div>
          </div>
          <div class="desc-bd">
            <div class="desc-brief">
              <div class="desc-brief-h2">
                专栏介绍
              </div>
              <div class="desc-brief-content">
                {{ desc.package_brief }}
              </div>
            </div>
          </div>

        </div>
      </van-tab>
      <van-tab title="目录">
        <van-list v-model="listLoading" class="list" :finished="listFinished" :finished-text="listFinishedText" @load="onListLoad">
          <div v-if="desc.content_can_acccess == 1" v-for="(item,index) in list" :key="item.content_id" class="list-item" @click="handlePlay(item,index)">
            <span style="display: inline-block;"><span class="list-item-type">{{item.content_type_name}}</span></span>
            <span class="list-item-title" :class="{'list-item-color':activeIndex == index}">{{index + 1}}. {{item.content_title}}</span>
            <div class="list-item-time" :class="{'list-item-color':activeIndex == index}">
              <van-icon v-if="item.content_type === 3" name="service-o" />
              <van-icon v-if="item.content_type === 2"  name="video-o" />
              <span style="margin-left:5px">{{ formatTime(item[typeMap[item.content_type]]) }}</span>
            </div>
          </div>
          <div v-if="desc.content_can_acccess == 0" v-for="(item,index) in list" :key="item.content_id" class="list-item">
            <span style="display: inline-block;"><span class="list-item-typeF">{{item.content_type_name}}</span></span>
            <span class="list-item-titleF">{{item.content_title}}</span>
            <div class="list-item-timeF">
              <van-icon v-if="item.content_type === 3" name="service-o" />
              <van-icon v-if="item.content_type === 2"  name="video-o" />
              <span style="margin-left:5px">{{ formatTime(item[typeMap[item.content_type]]) }}</span>
            </div>
          </div>
        </van-list>
      </van-tab>
      <van-tab title="评论">
        <div class="comment">
          <van-list v-model="listLoadingF" class="list" :finished="listFinishedF" :finished-text="listFinishedTextF" @load="onListLoadF">
            <div v-for="item in listF" :key="item.content_id" class="list-itemF mb10">
              <div class="list-itemF-header van-hairline--bottom">
                <div class="list-itemF-avatar">
                  <!-- <van-image class="list-itemF-avatar-img" :src="item.commnet_avatar" alt="" /> -->
                  <avatar :src="item.commnet_avatar" class="list-itemF-avatar-img" color="#fff" :size="28"></avatar>
                </div>
                <div class="list-itemF-inner">
                  <div class="list-itemF-name">{{ item.commnet_name }}</div>
                  <div v-if="item.commnet_rate" class="list-itemF-star">
                    <van-rate
                      :value="item.commnet_rate"
                      :size="14"
                      :color="globalData.theme.color1"
                      allow-half
                      void-icon="star"
                      void-color="#eee"
                      readonly
                    />
                  </div>
                </div>
                <div class="list-itemF-date">{{ item.comment_at }}</div>
              </div>
              <div class="list-itemF-content"><p>{{ item.commnet_content }}</p></div>
              <div v-if="item.comment_image.length" class="list-itemF-images">
                <van-image
                  v-for="(image,imageIndex) in item.comment_image"
                  :key="imageIndex"
                  @click="toPreview(item.comment_image,imageIndex)"
                  class="list-itemF-image"
                  :src="image"
                />
              </div>
            </div>
          </van-list>
        </div>
      </van-tab>
    </van-tabs>

    <!-- affix-bar -->
    <div v-if="tabActive === 2 && desc.content_can_acccess == 1" class="affix-bar">
      <van-button
        class="affix-bar-btn"
        block
        type="primary"
        @click="commentVisible = true"
      >立即评论</van-button>
    </div>
    <!-- 评价弹层 -->
    <van-popup v-model="commentVisible" position="bottom">
      <div class="popup">
        <van-icon name="close" class="popup-close" @click="commentVisible = false" />
        <div class="popup-body">
          <div class="popup-title">发表评价</div>
          <!-- <van-cell title="请选择您的评分" :border="false" /> -->
          <!-- <van-field name="rate" allow-half label="评分">
            <template #input>
              <van-rate
                v-model="rate"
                allow-half
                void-icon="star"
                void-color="#eee"
                :color="globalData.theme.color1"
              />
            </template>
          </van-field> -->
          <van-field
            v-model="remark"
            :border="false"
            label=""
            type="textarea"
            placeholder=""
            rows="1"
            autosize
          />
          <van-field label="图片">
            <template #input>
              <van-uploader v-model="uploaImgs" :max-count="3" :after-read="afterRead" />
            </template>
          </van-field>
        </div>
        <div class="popup-bottom">
          <van-button class="popup-confirm" :loading="btnLoading" type="primary" size="large" @click="onSubmit">提交评价</van-button>
        </div>
      </div>
    </van-popup>
    <div v-if="desc.content_can_acccess == 0" @click="topay" class='buy-btn'>¥ {{desc.package_price}}</div>
  </div>
</template>
<script>
import { Tab, Tabs, Field, Uploader} from 'vant'

import PageMixin from '@/mixins/page'
import { formatTime } from '@/common/util'
import DPlayer from 'dplayer';
import Avatar from 'vue-avatar'
import upload from '@/utils/upload'
import { isEmpty } from '@/common/validate'
import { wxPay } from '@/common/wx'

export default {
  name: 'ContentDetai',
  components: {
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Field.name]: Field,
    [Avatar.name]: Avatar,
    [Uploader.name]: Uploader,
  },
  mixins: [PageMixin],
  data() {
    return {
      desc: {},
      tabs: [],
      tabActive: 0,
      list: [],
      listLoading: false,
      btnLoading: false,
      listFinished: false,
      listFinishedText: '没有更多了',
      pageSize: 20,
      pageCurrent: 1,

      listF: [],
      listLoadingF: false,
      listFinishedF: false,
      listFinishedTextF: '没有更多了',
      pageCurrentF: 1,
      commentVisible: false,
      remark: '',
      uploaImgs: [],
      activeIndex:-1,
      contentType:0,
      audioUrl:'',
      playerOtions: {
        url: '',
        pic: ''
      },
      typeMap: {
        '1': 'content_mp3_duration',
        '2': 'content_video_duration',
        '3': 'content_mp3_duration'
      }
    }
  },
  created() {
    this.onDetail()
  },
  methods: {
    formatTime,
    onDetail() {
      this.$api.content_packagedetail({ package_id: this.$route.query.package_id }).then(res => {
        this.desc = res.data
        if(res.data.content_can_acccess){
          this.tabActive=1
        }
      })
    },
    onListLoad() {
      this.onPage()
    },
    onPage() {
      this.$api.content_packagecontent({ package_id: this.$route.query.package_id, 'per-page': this.pageSize, page: this.pageCurrent })
        .then(res => {
          this.listLoading = false
          this.list = Number(res._meta.currentPage) === 1 ? res.items : this.list.concat(res.items)
          this.listFinished = this.list.length >= res._meta.totalCount
          this.listFinishedText = this.list.length ? '没有更多了' : '暂无相关数据~'
          this.pageCurrent += 1
        })
    },
    onListLoadF() {
      this.onPageF()
    },
    onPageF() {
      this.$api.content_comment({ package_id: this.$route.query.package_id, 'per-page': this.pageSize, page: this.pageCurrentF })
        .then(res => {
          this.listLoadingF = false
          this.listF = Number(res._meta.currentPage) === 1 ? res.items : this.listF.concat(res.items)
          this.listFinishedF = this.listF.length >= res._meta.totalCount
          this.listFinishedTextF = this.listF.length ? '没有更多了' : '暂无相关数据~'
          this.pageCurrentF += 1
        })
    },
    afterRead(file, detail) {
      file.status = 'uploading'
      file.message = '上传中...'
      this.btnLoading = true
      upload({ file: file.file })
        .then(res => {
          this.uploaImgs.splice(detail.index, 1, { url: res.path, status: 'done', message: '上传成功' })
          this.btnLoading = false
        })
        .catch(err => {
          console.error(err)
          file.status = 'failed'
          file.message = '上传失败'
          this.btnLoading = false
        })
    },
    onSubmit() {
      if (isEmpty(this.remark)) {
        this.$toast('请填写评论内容')
        return
      }
      this.$toast.loading({
        message: '提交中...',
        forbidClick: true
      })
      const params = {
        comment_entity: this.desc.comment_entity,
        comment_content: this.remark,
        comment_image: this.uploaImgs.map(item => item.url)
      }

      this.$api.content_addcomment(params)
        .then(res => {
          this.$toast.success('提交评价成功')
          setTimeout(() => {
            // this.$router.back()
            this.commentVisible = false
            this.pageCurrentF = 1
            this.onPageF()
          }, 1500)
        })
        .catch(err => {
          this.$toast.fail('提交失败')
          console.error(err)
        })
    },
    toPreview(item,index){
        WeixinJSBridge.invoke("imagePreview", {
            "urls":item,
            "current": item[index]
        })
    },
    handlePlay(item,index){
      var that = this
      this.activeIndex = index
      this.contentType = item.content_type
      this.$api.content_play({content_id:item.content_id}).then(res => {
          // console.log(res)
          if(item.content_type != 2){
            this.$nextTick(() => {
              this.audioUrl = res.data.playuri
              this.$refs.audio.src = res.data.playuri
              setTimeout(()=>{
                that.$refs.audio.play()
              },150)
              
            })
          }else {
            // this.playerOtions.url = item.content_video
            // console.log(item.content_video)
            this.$nextTick(() => {
              this.player = new DPlayer({
                  container: document.getElementById('dplayer'),
                  video: {
                      url: res.data.playuri,
                      // pic: res.data.playimg
                  },
              });
              // this.player.switchVideo({
              //   url: res.data.playuri,
              // })
              // this.player.play()

            })
          }
      })
    },
    topay() {
        this.$toast.loading({
            mask: true,
            message: '支付中...',
            duration: 0
        })
        const params = {
            package_id: this.desc.package_id
        }
        // couponId && (params.coupon_id = couponId)
        this.$api.payment_package(params)
            .then(res => {
            const { appId, timeStamp, nonceStr, package: packageStr, signType, paySign } = res.data.jsApiParameters
            wxPay( appId, timeStamp, nonceStr, packageStr, signType, paySign)
                .then(res => {
                this.$toast.success('支付成功')
                setTimeout(() => {
                    history.go(0)
                }, 1500)
                })
                .catch(err => {
                this.$toast.clear()
                if(err == 9){
                  this.$toast.fail('取消支付')
                }else{
                  this.$toast.fail('支付失败')
                }
                setTimeout(() => {
                    
                }, 1500)
                console.error(err)
                })
            })
            .catch(err => {
            console.error(err)
            })
    },
  }
}
</script>

<style lang="less" scoped>

  .cover{
    width: 100%;
    height: 180px;
    position: relative;
    &-mp3{
      position: absolute;
      z-index: 10;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    &-img{
      width: 100%;
      height: 180px;
    }
  }

  .desc{
    &-hd{
      padding: 10px 15px;
      margin-bottom: 10px;
      background: #fff;
      &-h2{
        font-size: 18px;
        line-height: 2;
        font-weight: bold;
        margin-bottom: 10px;
      }
      &-bottom{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-size: 12px;
        color: #666;
        &-hd{
          margin-right: 20px;
        }
        &-bd{
          font-size: 10px;
          color: #f44;
        }
      }
    }
    &-brief{
      padding: 10px 15px;
      background: #fff;
      &-h2{
        font-size: 16px;
        line-height: 2;
        font-weight: bold;
        margin-bottom: 10px;
      }
      &-content{
        font-size: 14px;
        color: #333;
      }
    }
  }

  .list-item{
    display: flex;
    padding: 10px 15px;
    background: #fff;
    position: relative;
    &-media{
      margin-right: 10px;
      &-img{
        width: 170px;
        height: 82px;
      }
    }
    &-inner{
      flex: 1;
    }
    &-title{
      display: inline-block;
      width: 220px;
      font-size: 14px;
      color: #333;
      margin-left: 10px;
    }
    &-titleF{
      display: inline-block;
      width: 220px;
      font-size: 14px;
      color: #ccc;
      margin-left: 10px;
    }
    &-brief{
      min-height: 100PX;
      font-size: 12px;
      color: #666;
    }
    &-info{
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 12px;
      color: #666;
      &-bd{
        font-size: 10px;
        color: #f44;
      }
    }
    &-type{
      font-size: 10px;
      padding: 0 2px;
      border: 1px solid #333;
      color: #333;
    }
     &-typeF{
      font-size: 10px;
      padding: 0 2px;
      border: 1px solid #ccc;
      color: #ccc;
    }
    &-time{
      color: #777;
      display: flex;
      align-items: center;
      position: absolute;
      right: 15px;
    }
    &-timeF{
      color: #ccc;
      display: flex;
      align-items: center;
      position: absolute;
      right: 15px;
    }
    &-color{
      color: #00DE93;
    }
  }
  .comment{
    .list-itemF{
      background:#fff;
      &-avatar{
        margin-right:5px;
        &-img{
          width:40px;
          height:40px;
          border-radius: 50%;
          overflow: hidden;
        }
      }
      &-name{
        font-size: 14px;
        color:#555;
        line-height: 1;
      }
      &-inner{
        flex:1;
        padding-top:4px;
      }
      &-date{
        font-size: 12px;
        color:#999;
      }
      &-header{
        padding:10px 15px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
      &-content{
        padding:10px 15px;
        color:#999;
        font-size: 14px;
      }
      &-images {
        padding:0 15px 10px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
      &-image {
        width: 90px;
        // height: 90px;
        margin-right: 10px;
      }
    }
  }
  .popup {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    min-height: 300px;
    &-close {
      position: absolute;
      top: 10px;
      right: 15px;
      z-index: 20;
      color: #969799;
      font-size: 20px;
      text-align: center;
    }
    &-body {
      width: 100%;
      flex: 1;
    }
    &-title {
      font-size: 16px;
      text-align: center;
      padding: 10px 0;
    }
    &-bottom {
      width: 100%;
    }
    .rate-box {
      padding: 0px 15px;
      margin-bottom: 30px;
    }
  }
  .buy-btn{
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 40px;
    text-align: center;
    line-height: 40px;
    color: #fff;
    background: #00DE93;
  }
</style>

